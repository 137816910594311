<template>
  <BaseDialog
    :max-width="700"
    :propDialog="dialog"
    :title="dialogTitle"
    :topIcon="menu ? 'edit' : 'book'"
    botIcon="box"
    :validity="valid"
    :loader="loading"
    :goBack="menu"
    @confirm="updateInvoice"
    @selectTopIcon="menu = !menu"
    @selectBotIcon="addToInvoice(extras.foodContainer)"
    @closeDialog="setDialog"
    @cancel="menu = !menu"
  >
    <v-container v-show="!menu" class="px-0">
      <v-form v-model="formValid" @submit.prevent>
        <OrderDetails
          class="sticky-el--form"
          isInvoice
          :invoiceTableName.sync="cloneInvoice.tableName"
          :customerName.sync="cloneInvoice.customerName"
          :note.sync="cloneInvoice.note"
        />
        <BaseListToggle
          v-for="(entry, index) in entries"
          :key="index"
          :item="entry"
          canEdit
          quantityKey="finalQuantity"
          :nameKey="$i18n.locale === 'vi' ? 'viName' : 'enName'"
          valueKey="price"
          @increase="onIncrease(entry)"
          @decrease="onDecrease(entry)"
          @updateValue="onUpdateValue"
        />
      </v-form>
    </v-container>
    <DishList v-if="menu" container="invoice-dialog" :fullScreen="false" />
  </BaseDialog>
</template>

<script>
import OrderDetails from '@/components/orders/OrderDetails'
import DishList from '@/components/dishes/DishList'
import cloneDeep from 'lodash/cloneDeep'
import {
  displayErrorMessage,
  updateInvoice,
  getNewVal,
  createForm
} from '@/utils'
import { mapState } from 'vuex'

export default {
  name: 'UpdateInvoice',

  components: {
    OrderDetails,
    DishList
  },

  props: {
    invoice: Object,
    dialog: Boolean
  },

  data: () => ({
    cloneInvoice: {},
    entries: [],
    loading: false,
    menu: false,
    formValid: true,
    customerName: '',
    note: ''
  }),

  computed: {
    ...mapState('common', ['extras']),

    originalEntries() {
      return this.invoice.entries
    },

    valid() {
      if (this.entries.length > 0 && this.formValid) {
        return true
      } else {
        return false
      }
    },

    dialogTitle() {
      if (this.menu) {
        return this.cloneInvoice.tableName
      } else {
        return this.$t('invoice.update-invoice')
      }
    }
  },

  mounted() {
    this.$mitt.on('addToInvoice', this.addToInvoice)
  },
  beforeDestroy() {
    this.$mitt.off('addToInvoice', this.addToInvoice)
  },

  watch: {
    invoice: {
      immediate: true,
      handler: function(val) {
        this.cloneInvoice = cloneDeep(val)
        this.entries = this.cloneInvoice.entries
      }
    }
  },

  methods: {
    setDialog(val) {
      this.$emit('update:dialog', val)
    },

    onIncrease(entry) {
      const { finalQuantity, inKilogram } = entry
      entry.finalQuantity = getNewVal(finalQuantity, true, inKilogram)
    },

    onDecrease(entry) {
      const { finalQuantity, inKilogram } = entry
      const index = this.entries.findIndex(x => x.dishCode === entry.dishCode)
      if (
        (finalQuantity === 1 && !inKilogram) ||
        (finalQuantity === 0.1 && inKilogram)
      ) {
        this.entries.splice(index, 1)
      } else {
        entry.finalQuantity = getNewVal(finalQuantity, false, inKilogram)
      }
    },

    addToInvoice(dish) {
      const currentIndex = this.entries.findIndex(
        x => x.dishCode === dish.dishCode && x.price === dish.price
      )
      const originalIndex = this.originalEntries.findIndex(
        x => x.dishCode === dish.dishCode && x.price === dish.price
      )
      if (currentIndex > -1) {
        const { finalQuantity, inKilogram } = this.entries[currentIndex]
        this.entries[currentIndex].finalQuantity = getNewVal(
          finalQuantity,
          true,
          inKilogram
        )
      } else if (originalIndex > -1) {
        const cloneEntry = cloneDeep(this.originalEntries[originalIndex])
        cloneEntry.finalQuantity = cloneEntry.inKilogram ? 1 / 10 : 1
        this.entries.push(cloneEntry)
      } else {
        const cloneDish = cloneDeep(dish)
        cloneDish.finalQuantity = cloneDish.inKilogram ? 1 / 10 : 1
        delete cloneDish._id
        this.entries.push(cloneDish)
      }
    },

    onUpdateValue(payload) {
      const { item, key, value } = payload
      const index = this.entries.findIndex(x => x.dishCode === item.dishCode)
      const updatedEntry = { ...item }
      updatedEntry[key] = value
      this.entries.splice(index, 1, updatedEntry)
    },

    updateInvoice() {
      this.loading = true
      const newEntries = JSON.stringify(this.entries)
      const oldEntries = JSON.stringify(this.originalEntries)
      if (newEntries !== oldEntries) {
        this.cloneInvoice.status = 'pending'
        this.cloneInvoice.receivedAmount = 0
      }
      const form = createForm(this.cloneInvoice)
      form.set('entries', JSON.stringify(this.entries))
      updateInvoice(this.cloneInvoice._id, form)
        .then(() => this.setDialog(false))
        .catch(err => displayErrorMessage(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style></style>
